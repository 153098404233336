import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GeoGuardLogo from "../../assets/images/geoguard-logo@2x.png";
import DPPALogo from "../../assets/images/DPPA-logo.png";
import HeaderMainLogo from "../HeaderMainLogo";
import DatePicker from "./DatePicker";
import ModeToggle from "./ModeToggle";
import LayerSelector from "./LayerSelector";
import IndicatorsAccordionGroup from "./IndicatorsAccordionGroup";
import OverlaysAccordion from "./OverlaysAccordion";
import getCommonMaxDateFromProducts from "../../lib/getCommonMaxDateFromProducts";
import { dateSet } from "../../actions";

const Sidebar = (props) => {
  const { products } = useSelector((state) => state);
  const dispath = useDispatch();
  // Persist one product info tooltip at a time
  const [previousClickedTooltipTarget, setPreviousClickedTooltipTarget] =
    useState(null);
  const [closeTooltipOnNextClick, setCloseTooltipOnNextClick] = useState(false);

  useEffect(() => {
    dispath(dateSet(getCommonMaxDateFromProducts(products)));
  }, [dispath, products]);

  return (
    <aside className="sidebar">
      <div className="sidebar-container">
        <HeaderMainLogo logo={<img src={GeoGuardLogo} alt="GeoGuard Logo" />} />
        <div className="sidebar-upper-container">
          <LayerSelector {...props} />
          <div className="sidebar-upper-controls">
            <DatePicker />
            <ModeToggle />
          </div>
        </div>
        <div className="sidebar-indicator-container">
          <IndicatorsAccordionGroup
            previousClickedTooltipTarget={previousClickedTooltipTarget}
            setPreviousClickedTooltipTarget={setPreviousClickedTooltipTarget}
            closeTooltipOnNextClick={closeTooltipOnNextClick}
            setCloseTooltipOnNextClick={setCloseTooltipOnNextClick}
          />
          <OverlaysAccordion
            previousClickedTooltipTarget={previousClickedTooltipTarget}
            setPreviousClickedTooltipTarget={setPreviousClickedTooltipTarget}
            closeTooltipOnNextClick={closeTooltipOnNextClick}
            setCloseTooltipOnNextClick={setCloseTooltipOnNextClick}
          />
        </div>
        <div className="sidebar-footer">
          <img src={DPPALogo} alt="DPPA Logo" style={{ height: 30 }} />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
