import React from "react";
import PropTypes from "prop-types";
import { navigate, Link } from "gatsby";
import { Form, Button, Alert } from "react-bootstrap";
import { accountLoginRequest } from "../actions";
import { Trans } from "gatsby-plugin-react-i18next";

/**
 * shows the login form
 * @param {any} dispatch - dispatches actions to trigger state changes
 * @param {bool} account_is_logged_in - boolean stored in state
 * @param {any} account_login_response - response from the server
 * @returns {HTMLElement}
 */
const AccountLogin = ({
  dispatch,
  account_is_logged_in,
  account_login_response,
}) => {
  let handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      email: event.target.email.value,
      password: event.target.password.value,
    };
    dispatch(accountLoginRequest(data));
  };

  if (account_is_logged_in) {
    navigate("/");
    return (
      <div>
        <p>
          <Trans>Redirecting...</Trans>
        </p>
      </div>
    );
  }

  const { success, data } = account_login_response || {};

  return (
    <Form onSubmit={handleSubmit} className="form-signin">
      {success === false && data && data.detail && (
        <Alert variant="danger" className="my-0">
          {data.detail}
        </Alert>
      )}
      <section className="form-signin-groups rounded-bottom">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>
            <Trans>Email address</Trans>
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            autoComplete="email"
            isInvalid={success === false && data && data.email}
          />
          <Form.Control.Feedback type="invalid">
            {data && data.email && data.email.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>
            <Trans>Password</Trans>
          </Form.Label>
          <Form.Control
            type="password"
            name="password"
            autoComplete="current-password"
            isInvalid={success === false && data && data.password}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data && data.password && data.password.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Button variant="primary" type="submit" block>
            <Trans>Log In</Trans>
          </Button>
        </Form.Group>
        <div className="text-center">
          <Link variant="link" to="/account/register">
            <Trans>Register for an account</Trans>
          </Link>
        </div>
        <div className="text-center">
          <Button variant="link" href="mailto:geoguard-help@element84.com">
            <Trans>Help Desk</Trans>
          </Button>
        </div>
        <div className="text-center">
          <span>
            <p style={{ color: "grey", fontSize: "10px" }}>
              Geoguard is optimized for use with Chrome and Edge browsers only.
              Compatibility or functionality on other browsers may be limited or
              unavailable.
            </p>
          </span>
        </div>
      </section>
    </Form>
  );
};

AccountLogin.propTypes = {
  dispatch: PropTypes.any,
  account_is_logged_in: PropTypes.bool,
  account_login_response: PropTypes.any,
};

export default AccountLogin;
